import React,{useState,useCallback,useEffect,useContext,useMemo,useRef} from 'react'
import ReactFlow,{Background,Controls,addEdge,MiniMap,Panel,useReactFlow,getRectOfNodes,getTransformForBounds,BackgroundVariant,MarkerType,useNodesState,useEdgesState,updateEdge,ConnectionMode} from 'reactflow';
import 'reactflow/dist/style.css';
import {nanoid} from 'nanoid';
import Subheading from '../../components/Text/Subheading';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import {getGenkitCompletion,getGenkitSuggestions,getGenkitChat,getStructuredCompletion,getStructuredChat,getStructuredSuggestions} from '../../helpers/functions';
import {useNavigate,useOutletContext,useParams} from 'react-router-dom';
import {rt} from '../../helpers/firebase';
import analytics from '../../helpers/analytics';
import UserContext from '../../contexts/UserContext';
import Loading from '../Loading';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import Icon from '../../components/Text/Icon';
import HorizontalDivider from '../../components/Dividers/HorizontalDivider';
import {toPng} from 'html-to-image';
import {storage} from '../../helpers/firebase';
import {deleteObject,ref,uploadString} from 'firebase/storage';
import {onValue,remove,ref as rtRef,update} from 'firebase/database';
import ErrorContext from '../../contexts/ErrorContext';
import ProjectsContext from '../../contexts/ProjectsContext';
import ButtonDanger from '../../components/Buttons/ButtonDanger'
import TextNode from '../../components/Nodes/TextNode';
import {NodeProvider} from '../../contexts/NodeContext';
import RectNode from '../../components/Nodes/RectNode'
import HorCylinderNode from '../../components/Nodes/HorCylinderNode'
import RefTrapNode from '../../components/Nodes/RefTrapNode'
import TrapNode from '../../components/Nodes/TrapNode'
import ArrowRectNode from '../../components/Nodes/ArrowRectNode'
import PentNode from '../../components/Nodes/PentNode'
import SquircleNode from '../../components/Nodes/SquircleNode'
import CylinderNode from '../../components/Nodes/CylinderNode'
import CloudNode from '../../components/Nodes/CloudNode'
import CrossNode from '../../components/Nodes/CrossNode'
import DiamondRectNode from '../../components/Nodes/DiamondRectNode'
import SkewRectNode from '../../components/Nodes/SkewRectNode'
import CircleNode from '../../components/Nodes/CircleNode'
import ArrowNode from '../../components/Nodes/ArrowNode'
import RoundedNode from '../../components/Nodes/RoundedNode'
import TriangleNode from '../../components/Nodes/TriangleNode'
import HexNode from '../../components/Nodes/HexNode'
import DNode from '../../components/Nodes/DNode'
import TapeNode from '../../components/Nodes/TapeNode'
import ReccomendationNode from '../../components/Nodes/ReccomendationNode';
import DiamondNode from '../../components/Nodes/DiamondNode'
import arrowNodeIcon from '../../assets/nodeIcons/ArrowNode.svg'
import roundedNodeIcon from '../../assets/nodeIcons/RoundedNode.svg'
import triangleNodeIcon from '../../assets/nodeIcons/TriangleNode.svg'
import hexNodeIcon from '../../assets/nodeIcons/HexNode.svg'
import dNodeIcon from '../../assets/nodeIcons/DNode.svg'
import tapeNodeIcon from '../../assets/nodeIcons/TapeNode.svg'
import diamondNodeIcon from '../../assets/nodeIcons/DiamondNode.svg'
import cloudNodeIcon from '../../assets/nodeIcons/CloudNode.svg'
import crossNodeIcon from '../../assets/nodeIcons/CrossNode.svg'
import diamondRectNodeIcon from '../../assets/nodeIcons/DiamondRectNode.svg'
import skewRectNodeIcon from '../../assets/nodeIcons/SkewRectNode.svg'
import pentNodeIcon from '../../assets/nodeIcons/PentNode.svg'
import squircleNodeIcon from '../../assets/nodeIcons/SquircleNode.svg'
import cylinderNodeIcon from '../../assets/nodeIcons/CylinderNode.svg'
import arrowRectNodeIcon from '../../assets/nodeIcons/ArrowRectNode.svg'
import trapNodeIcon from '../../assets/nodeIcons/TrapNode.svg'
import refTrapNodeIcon from '../../assets/nodeIcons/RefTrapNode.svg'
import horCylinderNodeIcon from '../../assets/nodeIcons/HorCylinderNode.svg'
import rectNodeIcon from '../../assets/nodeIcons/RectNode.svg'
import textNodeIcon from '../../assets/nodeIcons/TextNode.svg'
import circleNodeIcon from '../../assets/nodeIcons/CircleNode.svg'
import youtubeLogo from '../../assets/integrations/youtube-logo.png'
import boxLogo from '../../assets/integrations/box-logo.png'
import docsLogo from '../../assets/integrations/docs-logo.png'
import sheetsLogo from '../../assets/integrations/sheets-logo.webp'
import slidesLogo from '../../assets/integrations/slides-logo.png'
import figmaLogo from '../../assets/integrations/figma-logo.png'
import loomLogo from '../../assets/integrations/loom-logo.png'
import driveLogo from '../../assets/integrations/drive-logo.webp'
import Select from '../../components/Inputs/Select';
import Text from '../../components/Text/Text';
import Bubble from '../../components/Chat/Bubble';
import Input from '../../components/Inputs/Input';
import classNames from 'classnames';
import smoothCurveIcon from '../../assets/icons/smooth-curve.svg'
import stepCurveIcon from '../../assets/icons/step-curve.svg'
import animatedCurveIcon from '../../assets/icons/animated-curve.svg'
import straightCurveIcon from '../../assets/icons/straight-curve.svg'
import arrowCurveIcon from '../../assets/icons/arrow-curve.svg'
import doubleArrowCurveIcon from '../../assets/icons/doublearrow-curve.svg'
import CreditsContext from '../../contexts/CreditsContext';
import usePageTitle from '../../hooks/usePageTitle';
import {logEvent} from 'firebase/analytics';
import Toggle from '../../components/Inputs/Toggle';
import useUserSubscription from '../../hooks/useUserSubscription';
import usePrevious from '../../hooks/usePrevious';
import {detailedDiff,diff} from 'deep-object-diff';
import {useEffectDebugger} from '../../hooks/useEffectDebugger';
import EmbedNode from '../../components/Nodes/EmbedNode';
import YoutubeNode from '../../components/Nodes/Integrations/YoutubeNode';
import LoomNode from '../../components/Nodes/Integrations/LoomNode';
import BoxNode from '../../components/Nodes/Integrations/BoxNode';
import GoogleDocsNode from '../../components/Nodes/Integrations/GoogleDocsNode';
import GoogleSheetsNode from '../../components/Nodes/Integrations/GoogleSheetsNode';
import GoogleSlidesNode from '../../components/Nodes/Integrations/GoogleSlidesNode';
import FigmaNode from '../../components/Nodes/Integrations/FigmaNode';
import GoogleDriveNode from '../../components/Nodes/Integrations/GoogleDriveNode';
import Card from '../../components/Cards/Card';

export default function Design({readOnly=false,embed=false}) {
    const [pageTitle,setPageTitle]=usePageTitle('Design')

    const {getUserPlan}=useUserSubscription()

    const {projectId,uid}=useParams()
    const navigate=useNavigate()
    const [user]=useContext(UserContext)
    const setError=useContext(ErrorContext)
    const [projects,updateProjects]=useContext(ProjectsContext)
    const [credits,updateCredits]=useContext(CreditsContext)
    const [userPlan,setUserPlan]=useState('free')
    const [lastReceivedProject,setLastReceivedProject]=useState(null)
    const [nodes,setNodes,onNodesChange]=useNodesState([]);
    const [edges,setEdges,onEdgesChange]=useEdgesState([]);
    const [title,setTitle]=useState('Untitled Flowchart')
    const [gridScale,setGridScale]=useState(10)
    const [showNodes,setShowNodes]=useState(false)
    const [showSettings,setShowSettings]=useState(false)
    const [showChat,setShowChat]=useState(false)
    const [projectLoading,setProjectLoading]=useState(true)
    const [chatLoading,setChatLoading]=useState(false)
    const [reactFlowInstance,setReactFlowInstance]=useState(null);
    const [madeChanges,setMadeChanges]=useState(false)
    const [suggestions,setSuggestions]=useState([])
    const [suggestionsLoading,setSuggestionsLoading]=useState(false)
    const [completionLoading,setCompletionLoading]=useState(false)
    const [publicAccess,setPublicAccess]=useState(false)
    const [completionPrompt,setCompletionPrompt]=useState('')
    const [showCompletionPromptInput,setShowCompletionPromptInput]=useState(false)
    const [hasUnapprovedChanges,setHasUnapprovedChanges]=useState(false)
    const [autosaveLoading,setAutosaveLoading]=useState(false)
    const [chatInput,setChatInput]=useState('')
    const [prevProject,setPrevProject]=useState(null)
    const [rightClickMenuDetails,setRightClickMenuDetails]=useState({show: false,right: 0,left: 0,top: 0,bottom: 0,selectedNodes: [],selectedEdges: []});
    const [chats,setChats]=useState([{role: 'model',content: 'Hello'}])
    const {getNodes}=useReactFlow();
    const ReactFlowRef=useRef(null);

    useEffect(() => {
        getUserPlan().then((plan) => {
            setUserPlan(plan)
        })
        updateCredits()
    },[user?.uid,getUserPlan,updateCredits])

    const {navRef}=useOutletContext()

    const onDragOver=useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect='move';
    },[]);

    const onDragStart=(event,nodeType) => {
        event.dataTransfer.setData('application/reactflow',nodeType);
        event.dataTransfer.effectAllowed='move';
    };

    const onEdgeUpdate=useCallback(
        (oldEdge,newConnection) => {return setEdges((els) => updateEdge(oldEdge,newConnection,els))},
        [setEdges]
    );

    const uploadProjectView=useCallback(() => {
        const imageWidth=512;
        const imageHeight=384;
        const nodesBounds=getRectOfNodes(getNodes());
        const transform=getTransformForBounds(nodesBounds,imageWidth,imageHeight,0.5,2);

        const storageRef=ref(storage,`users/${user.uid}/${projectId}/view.png`)

        toPng(document.querySelector('.react-flow__viewport'),{
            backgroundColor: '#d8f3df',
            width: imageWidth,
            height: imageHeight,
            style: {
                width: imageWidth,
                height: imageHeight,
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
        }).then(dataUrl => {
            uploadString(storageRef,dataUrl,'data_url')
        })
    },[user,projectId,getNodes])

    const onDrop=useCallback(
        (event) => {
            event.preventDefault();

            const type=event.dataTransfer.getData('application/reactflow');

            if(typeof type==='undefined'||!type) {
                return;
            }

            const position=reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            const newNode={
                id: nanoid(),
                type,
                position,
                style: {
                    width: 140
                },
                data: {
                    text: 'Untitled Node',
                }
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance,setNodes],
    );

    const onNodeContextMenu=useCallback((event,node) => {
        event.preventDefault();
        const pane=ReactFlowRef.current.getBoundingClientRect();
        setRightClickMenuDetails(rightClickMenuDetails => ({
            show: true,
            top: event.clientY<pane.height-300&&event.clientY-100,
            left: event.clientX<pane.width&&event.clientX,
            right: event.clientX>=pane.width&&pane.width-event.clientX,
            bottom: event.clientY>=pane.height-300&&pane.height-event.clientY+100,
            selectedNodes: [node.id],
        }));
    }
        ,[setRightClickMenuDetails]);

    const onEdgeContextMenu=useCallback((event,edge) => {
        event.preventDefault();
        const pane=ReactFlowRef.current.getBoundingClientRect();
        setRightClickMenuDetails(rightClickMenuDetails => ({
            show: true,
            top: event.clientY<pane.height-200&&event.clientY-100,
            left: event.clientX<pane.width&&event.clientX,
            right: event.clientX>=pane.width&&pane.width-event.clientX,
            bottom: event.clientY>=pane.height-200&&pane.height-event.clientY+100,
            selectedEdges: [edge.id],
        }));
    },[setRightClickMenuDetails]);

    // Add the uid to the selected node/edge data (use onSelectionChange)
    const onSelectionChange=useCallback((changes) => {
        const selectedNodeIds=changes.nodes.map((node) => node.id);
        const selectedEdgeIds=changes.edges.map((edge) => edge.id);

        setNodes((nodes) => nodes.map((node) => {
            if(selectedNodeIds.includes(node.id)) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        selectedBy: {uid: user.uid,displayName: user.displayName}
                    }
                }
            }

            return {
                ...node,
                data: {
                    ...node.data,
                    selectedBy: (node.data?.selectedBy?.uid===user.uid? false:node.data?.selectedBy)||false
                }
            }
        })
        )
        setEdges((edges) => edges.map((edge) => {
            if(selectedEdgeIds.includes(edge.id)) {
                return {
                    ...edge,
                    data: {
                        ...edge.data,
                        selectedBy: {uid: user.uid,displayName: user.displayName}
                    }
                }
            }
            return {
                ...edge,
                data: {
                    ...edge.data,
                    selectedBy: (edge.data?.selectedBy?.uid===user.uid? false:edge.data?.selectedBy)||false
                }
            }
        }))
    },[setNodes,setEdges,user]);

    const onPaneClick=useCallback(() => setRightClickMenuDetails(rightClickMenuDetails => {
        if(!madeChanges) {
            uploadProjectView();
        }
        setMadeChanges(true)

        if(rightClickMenuDetails.show) {
            return {
                ...rightClickMenuDetails,
                show: false,
            };
        }
        return rightClickMenuDetails;
    }),[setRightClickMenuDetails,madeChanges,setMadeChanges,uploadProjectView]);

    const handleGetSuggestions=async () => {
        if(suggestionsLoading) return
        setSuggestionsLoading(true)
        await updateCredits()
        if(credits===0) {
            setError('Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.')
            setSuggestionsLoading(false)
            navigate('/pricing')
            return
        }
        if(nodes.length>250) {
            setError('Sorry it looks like your flowchart is too large for the AI to process. Please remove some nodes to continue using this feature.')
            setSuggestionsLoading(false)
            return
        }
        try {
            let suggestionsResult=await getStructuredSuggestions({
                nodes: nodes.map((node) => {
                    return {
                        id: node.id,type: node.type,data: {text: node.data.text},position: {x: node.position.x,y: node.position.y},width: node.style.width,height: node.style.height
                    }
                }),edges: edges.map((edge) => {
                    return {
                        source: edge.source,sourceHandle: edge.sourceHandle.slice(-1),target: edge.target,targetHandle: edge.targetHandle.slice(-1),type: edge.type||'simplebezier'
                    }
                }),uid: user.uid
            })
            logEvent(analytics,'get_suggestions')
            suggestionsResult=suggestionsResult.data.suggestions
            setSuggestionsLoading(false)
            suggestionsResult.sort((a,b) => b.score-a.score)
            suggestionsResult=suggestionsResult.slice(0,3)
            if(suggestionsResult.length===0) {
                setError('Sorry, something went wrong. Please try again.')
                return
            }
            setSuggestions(suggestionsResult)
        } catch(error) {
            if(error.code==='functions/resource-exhausted') {
                setError('Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.')
                logEvent(analytics,'suggestions_resource_exhausted')
                setSuggestionsLoading(false)
                navigate('/pricing')
                return
            }
            logEvent(analytics,'suggestions_error')
            setError('Sorry, something went wrong. Please try again.')
            setSuggestionsLoading(false)
            return
        }
    }

    const handleGetCompletion=async () => {
        if(completionLoading) return
        setCompletionLoading(true)
        await updateCredits()
        if(credits===0) {
            setError('Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.')
            setCompletionLoading(false)
            navigate('/pricing')
            return
        }
        if(nodes.length===0) {
            setError('Please add some nodes to your flowchart before using this complete.')
            setCompletionLoading(false)
            return
        }
        if(nodes.length>250) {
            setError('Sorry it looks like your flowchart is too large for the AI to process. Please remove some nodes to continue using this feature.')
            setCompletionLoading(false)
            return
        }
        try {
            let completionResult=await getStructuredCompletion({
                prompt: completionPrompt,nodes: nodes.map((node) => {
                    return {
                        id: node.id,type: node.type,data: {text: node.data.text},position: {x: node.position.x,y: node.position.y},width: node.style.width,height: node.style.height
                    }
                }),edges: edges.map((edge) => {
                    return {
                        source: edge.source,sourceHandle: edge.sourceHandle.slice(-1),target: edge.target,targetHandle: edge.targetHandle.slice(-1),type: edge.type||'simplebezier'
                    }
                }),uid: user.uid

            })
            logEvent(analytics,'get_completion')
            completionResult=completionResult.data
            if(completionResult.newNodes.length===0&&completionResult.newEdges.length===0) {
                setError('Sorry, Flowsage AI could not generate any completions for your prompt. Please try again with a different prompt.')
                setCompletionLoading(false)
                return
            }
            addNewElements(completionResult.newNodes,completionResult.newEdges)
        } catch(error) {
            if(error.code==='functions/resource-exhausted') {
                setError('Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.')
                logEvent(analytics,'completion_resource_exhausted')
                setCompletionLoading(false)
                navigate('/pricing')
                return
            }
            setError('Sorry, something went wrong. Please try again.')
            logEvent(analytics,'completion_error')
            setCompletionLoading(false)
            return
        }
    }

    const handleGetChatResponse=async (e) => {
        if(e) {
            e.preventDefault()
        }
        if(chatLoading) return
        if(chatInput.length<=0) return
        await updateCredits()
        if(credits===0) {
            setChatInput('Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.')
            setChatLoading(false)
            navigate('/pricing')
            return
        }
        if(nodes.length>250||chats.length>500) {
            setError('Sorry it looks like your flowchart is too large for the AI to process. Please remove some nodes or chats to continue using this feature.')
            setChatLoading(false)
            return
        }
        getStructuredChat({
            nodes: nodes.map((node) => {
                return {
                    id: node.id,type: node.type,data: {text: node.data.text},position: {x: node.position.x,y: node.position.y},width: node.style.width,height: node.style.height
                }
            }),
            edges: edges.map((edge) => {
                return {
                    source: edge.source,sourceHandle: edge.sourceHandle.slice(-1),target: edge.target,targetHandle: edge.targetHandle.slice(-1),type: edge.type||'simplebezier'
                }
            }),chats: [...chats,{role: 'user',content: chatInput}],uid: user.uid
        }).then((data) => {
            console.log(data)
            logEvent(analytics,'get_chat_response')
            setChats(chats => [...chats,{role: 'model',content: data.data.response}])
            addNewElements(data.data?.newElements?.nodes||[],data.data?.newElements?.edges||[])
            setChatLoading(false)
        }).catch((error) => {
            if(error.code==='functions/resource-exhausted') {
                setChats(chats => [...chats,{role: 'model',content: 'Sorry, it looks like you ran out of Flowsage AI credits. Please upgrade your plan to continue using this feature.'}])
                logEvent(analytics,'chat_resource_exhausted')
                setChatLoading(false)
                navigate('/pricing')
                return
            }
            setChats(chats => [...chats,{role: 'model',content: 'Sorry, something went wrong. Please try again later.'}])
            logEvent(analytics,'chat_error')
            setChatLoading(false)
        })
        setChats(chats => [...chats,{role: 'user',content: chatInput}])
        setChatLoading(true)
        setChatInput('')
    }


    const handleDelete=() => {
        if(window.confirm('Are you sure you want to delete this project?')) {
            const projectRef=rtRef(rt,`projects/${uid||user?.uid}/${projectId}`)
            remove(projectRef).then(() => {
                const storageRef=ref(storage,`users/${user.uid}/${projectId}/view.png`)
                deleteObject(storageRef)
                updateProjects()
                navigate('/dashboard')
            })
        } else {
            setError('Project could not be deleted. Please try again later.')
        }
    }

    const addNewElements=(newNodes,newEdges) => {
        if(newNodes?.length===0&&newEdges?.length===0) {
            return
        }
        newNodes=newNodes.map((node) => {
            return {
                ...node,
                style: {
                    width: 140
                },
                data: {
                    ...node.data,
                    approved: false
                }
            }
        })
        newEdges=newEdges.map((edge) => {
            return {
                id: `reactflow_edge-${edge.source}${edge.source+edge.sourceHandle}-${edge.target}${edge.target+edge.targetHandle}`,
                ...edge,
                sourceHandle: edge.source+edge.sourceHandle,
                targetHandle: edge.target+edge.targetHandle,
            }
        })
        newNodes.forEach(node => {
            let tmp=''
            if(nodes.find(n => n.id===node.id)) {
                tmp=node.id
                node.id=nanoid()
            }
            newEdges.forEach(edge => {
                if(edge.source===tmp) {
                    edge.source=node.id
                }
                if(edge.target===tmp) {
                    edge.target=node.id
                }
            }
            )
        })
        const allNodes=[...nodes,...newNodes]
        newEdges=newEdges.filter(edge => {
            const hasValidNodes=allNodes.find(n => n.id===edge.source)||nodes.find(n => n.id===edge.target)
            if(!hasValidNodes) {
                setError('One or more edges could not be added because they are not connected to any nodes. Please try again.')
                return false
            }
            return !edges.find(e => e.source===edge.source&&e.target===edge.target)
        })
        console.log(newEdges)
        setCompletionLoading(false)
        if(userPlan==='free'&&nodes.length+newNodes.length>100) {
            setError('Sorry, it looks like you have reached the maximum number of nodes allowed on your plan. Please upgrade your plan to continue using this feature.')
            navigate('/pricing')
            return
        }
        setNodes(nodes => nodes.concat(newNodes))
        setEdges(edges => edges.concat(newEdges))
    }

    // useEffect(() => {
    //     getDoc(doc(db,'users',uid||user?.uid,'projects',projectId)).then(doc => {
    //         if(doc.exists()) {
    //             const project=doc.data()
    //             setProjectLoading(false)
    //             setNodes(project.nodes||[])
    //             setEdges(project.edges||[])
    //             setTitle(project.title||'Untitled Flowchart')
    //             setGridScale(project.gridScale||10)
    //             setChats(project.chats||[])
    //             setPublicAccess(project.public||false)
    //         } else {
    //             navigate('/dashboard')
    //         }
    //     })
    // },[projectId,navigate,user,uid,setNodes,setEdges])

    useEffect(() => {
        if(!projectId) {
            navigate('/dashboard')
        }

        const projectRef=rtRef(rt,`projects/${uid||user?.uid}/${projectId}`)
        onValue(projectRef,(snapshot) => {
            if(!snapshot.exists()) {
                navigate('/dashboard')
                return
            }
            const project=snapshot.val()
            setProjectLoading(false)
            setMadeChanges(false)
            if(project) {
                setNodes(prevNodes => (project.nodes||[]).map((node) => {
                    return {
                        ...node,
                        selected: prevNodes.find((n) => n.id===node.id)?.selected||false
                    }
                }))
                setEdges(prevEdges => (project.edges||[]).map((edge) => {
                    return {
                        ...edge,
                        selected: prevEdges.find((e) => e.id===edge.id)?.selected||false
                    }
                }))
                setTitle(project.title||'Untitled Flowchart')
                setGridScale(project.gridScale||10)
                setChats(project.chats||[])
                setPublicAccess(project.public||false)
                setLastReceivedProject({
                    nodes: (project.nodes||[]).map((node) => {
                        return {
                            ...node,
                            selected: false
                        }
                    }),
                    edges: (project.edges||[]).map((edge) => {
                        return {
                            ...edge,
                            selected: false
                        }
                    }),
                    title: project.title||'Untitled Flowchart',
                    gridScale: project.gridScale||10,
                    public: project.public||false,
                    chats: project.chats||[]
                })
            }
        })
    },[projectId,navigate,user,uid,setNodes,setEdges])

    useEffect(() => {
        const unapprovedNodes=nodes.filter(node => typeof node.data.approved==='boolean'&&!node.data.approved)
        setHasUnapprovedChanges(unapprovedNodes.length>0)
    },[nodes])

    useEffect(() => {
        if(!projectLoading) {
            setPageTitle(title)
        }
    },[projectLoading,title,setPageTitle])

    useEffect(() => {
        if(!projectLoading&&suggestions.length>0) {
            setNodes(nodes => {
                const reccNodes=suggestions.map((suggestion) => {
                    return {
                        id: nanoid(),
                        type: 'reccNode',
                        position: {x: suggestion.position.x,y: suggestion.position.y},
                        data: {
                            text: suggestion.suggestion,
                            score: suggestion.score
                        }
                    }
                })
                setSuggestions([])
                return nodes.concat(reccNodes)
            })
        }
    },[projectLoading,suggestions,setNodes])

    useEffect(() => {
        if(!projectLoading) {
            if(showCompletionPromptInput) {
                document.onkeyup=function(e) {
                    if(e.key==='Enter') {
                        handleGetCompletion()
                        setShowCompletionPromptInput(false)
                    }
                    if(e.key==='Escape') {
                        setShowCompletionPromptInput(false)
                    }
                }
            } else {
                document.onkeyup=null
            }
        }
    },[projectLoading,showCompletionPromptInput])

    const saveProject=useCallback(async () => {
        // if(!madeChanges) {
        //     return Promise.resolve()
        // }

        const projectRef=rtRef(rt,`projects/${uid||user?.uid}/${projectId}`)
        const currProject={
            nodes: nodes.map(node => {
                return {
                    ...node,
                    selected: false
                }
            }),
            edges: edges.map(edge => {
                return {
                    ...edge,
                    selected: false
                }
            }),
            title,
            gridScale,
            public: publicAccess,
            chats
        }
        setPrevProject(currProject)
        return update(projectRef,{
            ...currProject,
            updatedAt: Date.now()
        }).then(() => {
            return Promise.resolve()
        })
    },[projectId,title,uid,user?.uid,nodes,edges,gridScale,publicAccess,chats])

    useDidMountEffect(() => {
        const currProject={
            nodes: nodes.map(node => {
                return {
                    ...node,
                    selected: false
                }
            }),
            edges: edges.map(edge => {
                return {
                    ...edge,
                    selected: false
                }
            }),
            title,
            gridScale,
            public: publicAccess,
            chats
        }
        const changes=diff(prevProject||{},currProject)
        if(!Object.keys(changes).length||!Object.keys(diff(lastReceivedProject||{},currProject)).length) {
            return Promise.resolve()
        } else {
            setAutosaveLoading(true)
            if(!readOnly) {
                saveProject().then(() => {
                    setAutosaveLoading(false)
                })
            }
        }
    },[publicAccess,chats,nodes,edges,gridScale,projectId])

    const onConnect=useCallback(
        (params) => setEdges((eds) => {if(readOnly) return; return addEdge(params,eds)}),
        [setEdges,readOnly]
    );

    const nodeTypes=useMemo(() => ({
        textNode: TextNode,
        rectNode: RectNode,
        horCylinderNode: HorCylinderNode,
        refTrapNode: RefTrapNode,
        trapNode: TrapNode,
        arrowRectNode: ArrowRectNode,
        pentNode: PentNode,
        squircleNode: SquircleNode,
        cylinderNode: CylinderNode,
        cloudNode: CloudNode,
        crossNode: CrossNode,
        diamondRectNode: DiamondRectNode,
        skewRectNode: SkewRectNode,
        circleNode: CircleNode,
        arrowNode: ArrowNode,
        roundedNode: RoundedNode,
        triangleNode: TriangleNode,
        hexNode: HexNode,
        dNode: DNode,
        tapeNode: TapeNode,
        diamondNode: DiamondNode,
        reccNode: ReccomendationNode,
        embedNode: EmbedNode,
        youtubeNode: YoutubeNode,
        figmaNode: FigmaNode,
        loomNode: LoomNode,
        boxNode: BoxNode,
        googleDriveNode: GoogleDriveNode,
        googleDocsNode: GoogleDocsNode,
        googleSheetsNode: GoogleSheetsNode,
        googleSlidesNode: GoogleSlidesNode
    }),[]);

    const proOptions={
        hideAttribution: true,
    }

    if(projectLoading) {
        return <Loading />
    }


    // const connectingNodeId=useRef(null);
    // const onConnectStart=useCallback((_,{nodeId}) => {
    //     connectingNodeId.current=nodeId;
    // },[]);


    // const onConnectEnd=useCallback((event) => {
    //     const targetIsPane=(event.target).classList.contains(
    //         'react-flow__pane',
    //     );
    // },[]);

    const setNodeValue=(id,value,path='data.text') => {
        if(readOnly) {
            return
        }
        if(typeof value=='string'&&value.length>250) {
            setError('Sorry, it looks like the text you entered is too long.')
            return
        }
        setNodes((nodes) => nodes.map((node) => {
            if(node.id===id) {
                console.log(node)
                const pathParts=path.split('.');
                const tmp=node
                let tmp2=tmp
                for(let i=0;i<pathParts.length;i++) {
                    if(i===pathParts.length-1) {
                        tmp2[pathParts[i]]=value
                    } else {
                        tmp2=tmp2[pathParts[i]]
                    }
                }
                return tmp
            }
            return node;
        }))
    }

    const deleteNode=(id) => {
        if(readOnly) {
            return
        }
        setNodes((nodes) => nodes.filter((node) => node.id!==id))
        setEdges((edges) => edges.filter((edge) => edge.source!==id&&edge.target!==id))
    }

    const addNode=(type) => {
        if(userPlan==='free'&&nodes.length>100) {
            setError('Sorry, it looks like you have reached the maximum number of nodes allowed on your plan. Please upgrade your plan to continue using this feature.')
            navigate('/pricing')
            return
        }
        setNodes(nodes => [...nodes,{
            id: nanoid(),
            position: {x: 0,y: 0},
            style: {
                width: 140
            },
            type: type,
            data: {
                text: 'Untitled Node',
            },
        },])
    }

    const getNodeValue=(id,value='text') => {
        const node=nodes.find((node) => node.id===id);
        if(!node||!node.data) {
            return null
        }
        return typeof node?.data[value]==='undefined'? null:node?.data[value];
    }



    return (
        <div style={{height: `calc(100vh - ${window.getComputedStyle(navRef.current).height})`}} className='relative flex flex-row w-full overflow-hidden'>
            {(rightClickMenuDetails.show&&rightClickMenuDetails.selectedEdges?.length>0&&!readOnly)&&<div className='absolute z-50 flex flex-col justify-center p-2 text-xl rounded-md shadow-md items-left bg-light' style={{left: rightClickMenuDetails.left,top: rightClickMenuDetails.top,bottom: rightClickMenuDetails.bottom,right: rightClickMenuDetails.right}}>
                <Text className='text-sm text-zinc-400'>Type</Text>
                <div className='flex items-center w-full justify-left max-w-2tiny'>
                    <div onClick={() => {
                        setEdges(edges => {
                            return edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    return {
                                        id: edge.id,
                                        ...edge,
                                        type: 'simplebezier',
                                    }
                                }
                                return edge
                            }
                            )
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={smoothCurveIcon} alt='Smooth Curve' className='w-12' /></div>
                    <div onClick={() => {
                        setEdges(edges => {
                            return edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    return {
                                        id: edge.id,
                                        ...edge,
                                        type: 'step',
                                    }
                                }
                                return edge
                            }
                            )
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={stepCurveIcon} alt='Step Curve' className='w-12' /></div>
                </div>
                <HorizontalDivider />
                <Text className='text-sm text-zinc-400'>Arrows</Text>
                <div className='flex items-center w-full justify-left max-w-2tiny'>
                    <div onClick={() => {
                        setEdges(edges => {
                            const newEdges=edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    return {
                                        id: edge.id,
                                        ...edge,
                                        markerStart: {
                                            type: MarkerType.ArrowClosed,
                                            color: 'rgba(0,0,0,0)'
                                        },
                                        markerEnd: {
                                            type: MarkerType.ArrowClosed,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    }
                                }
                                return edge
                            })
                            return newEdges
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={straightCurveIcon} alt='No Arrows' className='w-12' /></div>
                    <div onClick={() => {
                        setEdges(edges => {
                            const newEdges=edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    return {
                                        id: edge.id,
                                        ...edge,
                                        markerStart: {
                                            type: MarkerType.ArrowClosed,
                                            color: 'rgba(0,0,0,0)'
                                        },
                                        markerEnd: {
                                            type: MarkerType.ArrowClosed,
                                            color: '#4a4a4d'
                                        }
                                    }
                                }
                                return edge
                            })
                            return newEdges
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={arrowCurveIcon} alt='One Arrow' className='w-12' /></div>
                    <div onClick={() => {
                        setEdges(edges => {
                            const newEdges=edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    return {
                                        id: edge.id,
                                        ...edge,
                                        markerStart: {
                                            type: MarkerType.ArrowClosed,
                                            color: '#4a4a4d'
                                        },
                                        markerEnd: {
                                            type: MarkerType.ArrowClosed,
                                            color: '#4a4a4d'
                                        }
                                    }
                                }
                                return edge
                            })
                            return newEdges
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={doubleArrowCurveIcon} alt='Double Arrow' className='w-12' /></div>
                </div>
                <HorizontalDivider />
                <Text className='text-sm text-zinc-400'>Dashed</Text>
                <div className='flex items-center w-full justify-left max-w-2tiny'>
                    <div onClick={() => {
                        setEdges(edges => {
                            return edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    edge.animated=false
                                }
                                return edge
                            }
                            )
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={smoothCurveIcon} alt='Solid Stroke' className='w-12' /></div>
                    <div onClick={() => {
                        setEdges(edges => {
                            return edges.map(edge => {
                                if(rightClickMenuDetails.selectedEdges.includes(edge.id)) {
                                    edge={
                                        ...edge,
                                        animated: true,
                                    }
                                }
                                return edge
                            }
                            )
                        })
                    }} className='text-sm cursor-pointer select-none'><img src={animatedCurveIcon} alt='Dashed Stroke' className='w-12' /></div>
                </div>
                <HorizontalDivider />
                <Text onClick={() => {
                    setEdges(edges => edges.filter(edge => !rightClickMenuDetails.selectedEdges.includes(edge.id)))
                    setRightClickMenuDetails(rightClickMenuDetails => ({
                        ...rightClickMenuDetails,
                        show: false,
                    }))
                }} className='text-sm cursor-pointer select-none text-danger'>Remove Edge</Text>
            </div>}
            {(rightClickMenuDetails.show&&rightClickMenuDetails.selectedNodes?.length>0&&!readOnly)&&<div className='absolute z-50 flex flex-col justify-center p-2 text-xl rounded-md shadow-md items-left bg-light' style={{left: rightClickMenuDetails.left,top: rightClickMenuDetails.top,bottom: rightClickMenuDetails.bottom,right: rightClickMenuDetails.right}}>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'white'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='' icon='square' />White</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'gray'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-gray' icon='square-fill' />Gray</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'brown'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-brown' icon='square-fill' />Brown</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'orange'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-orange' icon='square-fill' />Orange</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'yellow'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-yellow' icon='square-fill' />Yellow</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'green'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-green' icon='square-fill' />Green</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'blue'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-blue' icon='square-fill' />Blue</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'purple'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-purple' icon='square-fill' />Purple</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'pink'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-pink' icon='square-fill' />Pink</Text>
                <Text onClick={() => {
                    setNodes(nodes => {
                        return nodes.map(node => {
                            if(rightClickMenuDetails.selectedNodes.includes(node.id)) {
                                node.data={
                                    ...node.data,
                                    color: 'red'
                                }
                            }
                            return node
                        }
                        )
                    })
                }} className='flex items-center gap-2 text-sm font-semibold cursor-pointer select-none justify-left'><Icon className='inline text-xl !px-0' iconClassName='text-node-red' icon='square-fill' />Red</Text>
                <HorizontalDivider />
                <Text onClick={() => {
                    setNodes(nodes => nodes.filter(node => !rightClickMenuDetails.selectedNodes.includes(node.id)))
                    setEdges(edges => edges.filter(edge => !rightClickMenuDetails.selectedNodes.includes(edge.source)&&!rightClickMenuDetails.selectedNodes.includes(edge.target)))
                    setRightClickMenuDetails(rightClickMenuDetails => ({
                        ...rightClickMenuDetails,
                        show: false,
                    }))
                }} className='text-sm cursor-pointer select-none text-danger'>Remove Node</Text>

            </div>}
            {showNodes&&<div className='flex flex-col w-full h-full gap-2 p-2 border-r shadow-md lg:w-1/5 border-dark-100'>
                <div className='flex flex-row items-center justify-between w-full gap-2'>
                    <Subheading>Nodes</Subheading>
                    <div className='flex flex-row items-center gap-2'>
                        <div onClick={() => setShowNodes(false)} className='cursor-pointer'><Icon icon='x' className='text-2xl' /></div>
                    </div>
                </div>
                <div className='grid w-full grid-cols-3 gap-2 lg:grid-cols-6'>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'textNode')} onClick={() => addNode('textNode')}><img src={textNodeIcon} alt='Text Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'rectNode')} onClick={() => addNode('rectNode')}><img src={rectNodeIcon} alt='Rect Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'squircleNode')} onClick={() => addNode('squircleNode')}><img src={squircleNodeIcon} alt='Squircle Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'roundedNode')} onClick={() => addNode('roundedNode')}><img src={roundedNodeIcon} alt='Rounded Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'diamondNode')} onClick={() => addNode('diamondNode')}><img src={diamondNodeIcon} alt='Diamond Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'arrowRectNode')} onClick={() => addNode('arrowRectNode')}><img src={arrowRectNodeIcon} alt='Arrow Rect Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'diamondRectNode')} onClick={() => addNode('diamondRectNode')}><img src={diamondRectNodeIcon} alt='Diamond Rect Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'skewRectNode')} onClick={() => addNode('skewRectNode')}><img src={skewRectNodeIcon} alt='Skew Rect Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'cylinderNode')} onClick={() => addNode('cylinderNode')}><img src={cylinderNodeIcon} alt='Cylinder Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'horCylinderNode')} onClick={() => addNode('horCylinderNode')}><img src={horCylinderNodeIcon} alt='Horizontal Cylinder Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'refTrapNode')} onClick={() => addNode('refTrapNode')}><img src={refTrapNodeIcon} alt='Ref Trap Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'trapNode')} onClick={() => addNode('trapNode')}><img src={trapNodeIcon} alt='Trap Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'hexNode')} onClick={() => addNode('hexNode')}><img src={hexNodeIcon} alt='Hex Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'pentNode')} onClick={() => addNode('pentNode')}><img src={pentNodeIcon} alt='Pent Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'circleNode')} onClick={() => addNode('circleNode')}><img src={circleNodeIcon} alt='Circle Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'triangleNode')} onClick={() => addNode('triangleNode')}><img src={triangleNodeIcon} alt='Triangle Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'cloudNode')} onClick={() => addNode('cloudNode')}><img src={cloudNodeIcon} alt='Cloud Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'crossNode')} onClick={() => addNode('crossNode')}><img src={crossNodeIcon} alt='Cross Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'arrowNode')} onClick={() => addNode('arrowNode')}><img src={arrowNodeIcon} alt='Arrow Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'dNode')} onClick={() => addNode('dNode')}><img src={dNodeIcon} alt='D Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'tapeNode')} onClick={() => addNode('tapeNode')}><img src={tapeNodeIcon} alt='Tape Node' className='max-w-4tiny' /></div>
                </div>
                <Subheading>Integrations</Subheading>
                <div className='grid w-full grid-cols-3 gap-2 lg:grid-cols-5'>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'youtubeNode')} onClick={() => addNode('youtubeNode')}><img src={youtubeLogo} alt='Youtube Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'loomNode')} onClick={() => addNode('loomNode')}><img src={loomLogo} alt='Loom Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'figmaNode')} onClick={() => addNode('figmaNode')}><img src={figmaLogo} alt='Figma Node' className='p-1 max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'googleDocsNode')} onClick={() => addNode('googleDocsNode')}><img src={docsLogo} alt='Google Docs Node' className='p-0.5 max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'googleSheetsNode')} onClick={() => addNode('googleSheetsNode')}><img src={sheetsLogo} alt='Google Sheets Node' className='max-w-4tiny p-0.5' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'googleSlidesNode')} onClick={() => addNode('googleSlidesNode')}><img src={slidesLogo} alt='Google Slides Node' className='max-w-4tiny p-0.5' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'boxNode')} onClick={() => addNode('boxNode')}><img src={boxLogo} alt='Box Node' className='max-w-4tiny' /></div>
                    <div draggable='true' className='flex flex-col items-center justify-center text-xl rounded-md cursor-pointer select-none aspect-square bg-light hover:bg-zinc-200' onDragStart={(event) => onDragStart(event,'googleDriveNode')} onClick={() => addNode('googleDriveNode')}><img src={driveLogo} alt='Google Drive Node' className='max-w-4tiny' /></div>
                </div>
            </div>}
            <div className={`w-full`} style={{height: `calc(100vh - ${window.getComputedStyle(navRef.current).height})`}}>
                {showCompletionPromptInput&&<div className='absolute z-50 flex flex-col items-end justify-start w-full h-full bg-opacity-10 bg-secondary-950'>
                    <div className='flex flex-col items-center justify-center p-2 mx-auto mt-24 text-xl border rounded-md shadow-md lg:mr-12 bg-light'>
                        <Input className='w-full ' value={completionPrompt} onChange={(e) => setCompletionPrompt(e.target.value)} placeholder='Describe the flowchart you want...' detailsClassName='text-dark' details='Press Esc to close. Press Enter to generate.' />
                    </div></div>}

                <NodeProvider value={[setNodeValue,getNodeValue,deleteNode]}>
                    <ReactFlow ref={ReactFlowRef} onInit={(reactflow) => {setReactFlowInstance(reactflow)}} nodeTypes={nodeTypes} proOptions={proOptions} fitView defaultViewport={{x: 0,y: 0,zoom: 1.5}} nodes={nodes} edges={edges} onNodesChange={onNodesChange} onDrop={onDrop} onDragOver={onDragOver} onEdgesChange={onEdgesChange} onConnect={onConnect} snapGrid={[gridScale,gridScale]} snapToGrid={gridScale>0} onEdgeContextMenu={onEdgeContextMenu} selectionOnDrag={true} elementsSelectable={!readOnly} nodesDraggable={!readOnly} nodesConnectable={!readOnly} edgesUpdatable={!readOnly} connectionMode={ConnectionMode.Loose} onPaneClick={onPaneClick} nodesFocusable={!readOnly} onNodeContextMenu={onNodeContextMenu} onEdgeUpdate={onEdgeUpdate} onSelectionChange={onSelectionChange} defaultEdgeOptions={{
                        markerStart: {
                            type: MarkerType.ArrowClosed,color: 'rgba(0,0,0,0)'
                        },markerEnd: {
                            type: MarkerType.ArrowClosed,color: 'rgba(0,0,0,0)'
                        }
                    }}>
                        <Background variant={BackgroundVariant.Dots} gap={10} />
                        <Controls />
                        {!readOnly&&<><Panel position='top-right'><div className='relative flex flex-row items-center justify-center px-1 py-2 text-xl rounded-md shadow-md bg-light'>
                            <Icon onClick={() => !completionLoading&&setShowCompletionPromptInput(true)} className={completionLoading? 'animate-spin':'cursor-pointer border-r'} label={!completionLoading&&'Complete with Flowsage AI'} icon={completionLoading? 'bounding-box-circles':'pencil'} />
                            <Icon onClick={handleGetSuggestions} className={suggestionsLoading? 'animate-spin':'cursor-pointer border-r'} label={!suggestionsLoading&&'Optimize with Flowsage AI'} icon={suggestionsLoading? 'bounding-box-circles':'speedometer2'} />
                            <Icon onClick={() => {setShowChat((showChat) => !showChat); setShowSettings(false)}} className='border-r cursor-pointer' label='Chat with Flowsage AI' icon='chat-left-text' />
                            <Icon className='cursor-pointer' onClick={() => {setShowSettings((showSettings) => !showSettings); setShowChat(false)}} icon='gear' label='Project Settings' />
                        </div>
                            {hasUnapprovedChanges&&<div className='flex flex-row items-center justify-center px-2 py-2 mt-2 text-xl rounded-md shadow-md bg-light'>
                                <Text className='flex-1 text-xs font-semibold text-left lg:text-sm'>Accept Changes?</Text>
                                <Icon onClick={
                                    () => {
                                        setNodes(nodes => nodes.map(node => {
                                            if(typeof node.data.approved==='boolean'&&!node.data.approved) {
                                                node.data.approved=true
                                            }
                                            return node
                                        }))
                                    }} className='flex ml-2 !p-1 !px-2 rounded-md border-r cursor-pointer aspect-square bg-success-300 text-success-700 items-center justify-center hover:bg-success-400 transition-colors hover:text-success-800' icon='check' />
                                <Icon onClick={() => {
                                    setNodes(nodes => nodes.filter(node => typeof node.data.approved==='boolean'? node.data.approved:true))
                                }} className='flex ml-2 !p-1 !px-2 rounded-md border-r cursor-pointer aspect-square bg-danger-300 text-danger-700 items-center justify-center hover:bg-danger-400 transition-colors hover:text-danger-800' icon='x' />
                            </div>}</Panel>
                            <Panel position='top-left'><div className='flex flex-col items-center justify-center p-2 px-0 text-xl rounded-md shadow-md bg-light'><Icon onClick={() => setShowNodes((showNodes) => !showNodes)} className='cursor-pointer' icon='plus-circle' labelPosition='right' label='Add Node' /></div></Panel> </>
                        }
                        <MiniMap nodeStrokeWidth={3} zoomable pannable />
                    </ReactFlow>
                </NodeProvider>
            </div>
            {
                showSettings&&<div className='flex flex-col w-3/5 h-full gap-6 p-4 px-6 border-r shadow-md lg:w-1/5 border-dark-100'>
                    <div className='flex flex-row items-center justify-between w-full gap-2 -mb-4'>
                        <Subheading className='!pb-0'>Project Settings</Subheading>
                        <div className='flex flex-row items-center gap-2 mt-3'>
                            <div onClick={() => setShowSettings(false)} className='cursor-pointer'><Icon icon='x' className='text-2xl' /></div>
                        </div>
                    </div>
                    <HorizontalDivider />
                    <div className='flex items-center justify-between'>
                        <Subheading className='!text-md lg:!text-lg !font-medium'>Grid Size</Subheading>
                        <div className='flex items-center justify-center w-1/3' >
                            <Select className='!w-32' options={[{id: -1,value: 'No grid'},{id: 5,value: 'Small'},{id: 10,value: 'Medium'},{id: 20,value: 'Large'}]} currentOption={gridScale} setCurrentOption={setGridScale} />
                        </div>
                    </div>
                    <div className='flex items-center justify-between'>
                        <Subheading className='!text-md lg:!text-lg !font-medium'>Enable Public Access</Subheading>
                        <div className='flex items-center justify-center w-1/3' >
                            <Toggle className='p-1' checked={publicAccess} setChecked={setPublicAccess} />
                        </div>
                    </div>
                    <HorizontalDivider />
                    <ButtonDanger onClick={handleDelete}>Delete Project</ButtonDanger>
                </div>
            }
            {
                showChat&&<div style={{height: `calc(100vh - ${window.getComputedStyle(navRef.current).height})`}} className={`relative flex flex-col w-[150%] lg:w-2/5 gap-2 p-2 border-r shadow-md border-dark-100`}>
                    <div className='flex flex-row items-center justify-between w-full gap-2'>
                        <Subheading>Chat with Flowsage AI</Subheading>
                        <div className='flex flex-row items-center gap-2'>
                            <div onClick={() => setShowChat(false)} className='cursor-pointer'><Icon icon='x' className='text-2xl' /></div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center w-full gap-4 justify-stretch'>
                        {
                            chats.length===0? (
                                <div className='flex flex-col items-center justify-center w-full h-max-[calc(100vh-300px)] gap-2 pt-24'>
                                    <Subheading className='text-lg font-medium text-center text-dark/50'>No chats yet</Subheading>
                                    <Text className='text-lg font-medium text-center text-dark/50'>Type a message to get started</Text>
                                </div>):(
                                <div className={`w-full py-4 overflow-scroll h-[calc(100vh-225px)] flex-grow rounded-lg `}>
                                    {chats.map((chat,index) => {
                                        return <Bubble key={index} chat={chat} index={index} deleteChatBubble={(index) => setChats(chats => chats.filter((_,i) => i!==index))} />
                                    }
                                    )}
                                </div>
                            )
                        }
                    </div>
                    <form onSubmit={handleGetChatResponse} className='absolute bottom-0 left-0 right-0 flex flex-row items-center justify-center gap-2 p-2'>
                        <Input value={chatInput} setValue={setChatInput} containerClassName='w-full' className='!w-full' placeholder='Type a message...' />
                        <ButtonPrimary type='submit' className={classNames('!w-16 !h-full px-4',chatLoading&&'animate-bounce')}>Send</ButtonPrimary>
                    </form>
                </div>
            }
        </div>
    )
}
