import React,{useCallback,useContext,useState,useEffect,useRef} from 'react'
import {Outlet} from 'react-router-dom'
import {ReactFlowProvider} from 'reactflow'
import Navbar from '../../components/Nav/Navbar'
import {CreditsProvider} from '../../contexts/CreditsContext'
import {ProjectsProvider} from '../../contexts/ProjectsContext'
import {getCredits} from '../../helpers/functions'
import UserContext from '../../contexts/UserContext'
import ErrorContext from '../../contexts/ErrorContext'
import {getDocs,collection,orderBy} from 'firebase/firestore'
import {db,rt,storage} from '../../helpers/firebase'
import {getDownloadURL,ref} from 'firebase/storage'
import FeedbackCard from '../../components/Cards/FeedbackCard'
import {ref as rtRef,get,query,equalTo,orderByChild,child,onValue,push} from 'firebase/database'

export default function Main() {

    const setError=useContext(ErrorContext)

    const [user]=useContext(UserContext)

    const navRef=useRef(null)

    const [credits,setCredits]=useState(0)
    const [loading,setLoading]=useState(true)
    const [projects,setProjects]=useState([])



    const updateCredits=useCallback(() => {
        getCredits().then(response => {
            setCredits(response.data.credits||0)
        }).catch(err => {
            window.location.reload()
        })
    },[])

    // Sort projects by updatedAt whenever a project is updated
    useEffect(() => {
        if(projects.length>0) {
            setProjects([...projects].sort((a,b) => b.updatedAt-a.updatedAt))
        }
    },[projects])


    const updateProjects=useCallback(() => {
        const userRef=rtRef(rt,'projects/'+user.uid)
        get(userRef).then(projectsSnapshot => {
            let projectsResponse=projectsSnapshot.val()
            projectsResponse=projectsResponse? Object.keys(projectsResponse).map((key) => {
                const projectRef=ref(storage,`users/${user.uid}/${key}/view.png`)
                return {
                    id: key,
                    projectRef: projectRef,
                    link: key,
                    ownerId: user.uid,
                    ...projectsResponse[key]
                }
            }):[]
            if(projectsResponse?.length===0) {
                setLoading(false)
                return
            }
            setProjects(projectsResponse)
            setLoading(false)
        })
        // Find all projects where the user's email is in the collaborators list project.collaborators.child.email === user.email
        const collabRef=rtRef(rt,'collaboratorsMap/'+encodeURIComponent(user.email).replace('.','%2E'))
        get(collabRef).then(collabSnapshot => {
            let collabResponse=collabSnapshot.val()
            if(collabResponse) {
                Object.keys(collabResponse).forEach((projectId) => {
                    const projectRtRef=rtRef(rt,'projects/'+collabResponse[projectId].ownerId+'/'+projectId)
                    get(projectRtRef,'value').then(projectSnapshot => {
                        const project=projectSnapshot.val()
                        const storageRef=ref(storage,`/users/${collabResponse[projectId].ownerId}/${projectId}/view.png`)
                        setProjects((prevProjects) => {
                            return [...prevProjects.filter((prevProject) => prevProject.id!==projectId),{
                                id: projectId,
                                projectRef: storageRef,
                                link: `${collabResponse[projectId].ownerId}/${projectId}`,
                                ownerId: collabResponse[projectId].ownerId,
                                ...collabResponse[projectId],
                                ...project
                            }].sort((a,b) => {
                                console.log(a.updatedAt,b.updatedAt,a.updatedAt-b.updatedAt)
                                return a.updatedAt-b.updatedAt
                            })
                        })
                    })
                })
            }
        })
    },[user?.uid])

    useEffect(() => {
        updateProjects()
        updateCredits()
    },[updateCredits,updateProjects])

    return (
        <div className='w-full min-h-screen'>
            <CreditsProvider value={[credits,updateCredits]}>
                <ProjectsProvider value={[projects,updateProjects]}>
                    <ReactFlowProvider>
                        <Navbar ref={navRef} />
                        <Outlet context={{navRef}} />
                        <FeedbackCard />
                    </ReactFlowProvider>
                </ProjectsProvider>
            </CreditsProvider>
        </div>
    )
}
